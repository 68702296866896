import React from "react";

class ToolsHeader extends React.Component
{
  render()
  {
    if (!this.props.show)
    {
      return null;
    }

    return (
      <div
        className = "mt-4"
      >
        <div
          className = "flex items-center"
        >
          <img
            alt = "Icon"
            className = "fill-current h-8 mr-2 w-8"
            height = "54"
            viewBox = "0 0 54 54"
            width = "54"
            src = { this.props.icon }
          />
          <h1
            className = "text-lg font-bold mb-1"
          >
            { this.props.name }
          </h1>
        </div>

        <p>
        { this.props.description }
        </p>
      </div>
    );
  }
}

export default ToolsHeader;