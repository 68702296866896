import React from "react";
import WorkInProgressIllustration from "../images/work-in-progress-illustration.svg";

function WorkInProgress() {
  return (
    <div>
      <div
        className = "w-full sm:w-1/2 md:w-1/3 lg:w-1/4 my-2 mx-auto"
      >
        <img
          alt = "Balance"
          className = ""
          src = { WorkInProgressIllustration }
        />
        <h2
          className="text-center bg-yellow-400 text-2xl font-bold inline-block mb-8 p-3"
        >
          Page under Development
        </h2>
      </div>
    </div>
  );
}

export default WorkInProgress;
