import React from "react";
import WithLocation from "../withLocation";
import WorkInProgress from "../workInProgress.js";

const PreSentenceDetentionCalculator = (props) =>
{
  return (
    <WorkInProgress />
  );
}

export default WithLocation(PreSentenceDetentionCalculator);