import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ToolsLayout from "../../../components/toolsLayout";
import SEO from "../../../components/seo";
import PreSentenceDetentionCalculator from "../../../components/preSentenceDetention/preSentenceDetentionCalculator";
import PreSentenceDetentionCalculatorIllustration from "../../../images/pre-sentence-detention-calculator-illustration.svg";

const ToolsPreSentenceDetentionPage = (props) =>
{
  const { site } = useStaticQuery(
    graphql`
      query
      {
        site
        {
          siteMetadata
          {
            tools
            {
              id
              name
              description
              link
            }
          }
        }
      }
    `
  );

  const tool = site.siteMetadata.tools.filter(t => t.id === 2)[0];

  return (
    <ToolsLayout
      show = { true }
      name = { tool.name }
      description = { tool.description }
      icon = { PreSentenceDetentionCalculatorIllustration }
      goBackDestination = "/tools"
      goBackText = "← Back to Tools Menu"
    >
      <SEO
        keywords = { [`LawChest`, `pre-sentence`, `sentence`, `calculator`] }
        title = "Pre-Sentence Detention Calculator"
      />

      <section
        id = "utilitySection"
      >
        <PreSentenceDetentionCalculator />
      </section>
    </ToolsLayout>
  );
}

export default ToolsPreSentenceDetentionPage;
