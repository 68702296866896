import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import Footer from "./footer";
import ToolsHeader from "./toolsHeader.js";
import GoBack from "./goBack";

class ToolsLayout extends React.Component
{
  constructor(props)
  {
    super(props);
  }

  render()
  {
    return (
      <div
        className = "flex"
      >
        <div
          className="flex flex-col font-sans w-full min-h-screen text-gray-900"
        >
          <Header isAdminPage = { false } />

          <main
            id = "toolsLayoutMain"
            className = "flex-grow md:justify-center max-w-4xl mx-auto px-4 w-full"
          >
            {
              (this.props.show || this.props.goBackDestination) &&
                <div
                  className = "pt-4"
                >
                  {
                    this.props.goBackDestination &&
                      <GoBack
                        text = { this.props.goBackText }
                        destination = { this.props.goBackDestination }
                      />
                  }
                  <ToolsHeader
                    show = { this.props.show }
                    icon = { this.props.icon }
                    name = { this.props.name }
                    description = { this.props.description }
                  />
                </div>
            }
            <div
              className = "py-8 md:p-8"
            >
              {this.props.children}
            </div>
          </main>
    
          <Footer />
        </div>
      </div>
    );
  }
}

ToolsLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default ToolsLayout;
